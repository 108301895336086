// -- basic library --
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PfHeader, { HeaderMenuItem } from 'shared/components/molecules/PfHeader';
import { authActions, authSelector } from 'user/redux/slices/authSlice';
import { getLoginId } from 'shared/utils/else/localStorage';
import { useSelector } from 'react-redux';
import { IconNames } from '@blueprintjs/icons';

const header_menu_items: HeaderMenuItem[] = [
  {
    id: 'roles',
    text: 'ロール一覧',
    for_admin: true,
    icon: IconNames.CROWN
  },
  {
    id: 'users',
    text: 'ユーザー管理',
    for_admin: true,
    icon: IconNames.PEOPLE
  },
  {
    id: 'streams-packages-favorite',
    text: 'ストリームパッケージお気に入り一覧',
    for_admin: true,
    icon: IconNames.STAR
  },
  {
    id: 'devices',
    text: 'デバイス',
    for_admin: true,
    icon: IconNames.MOBILE_VIDEO
  },
  {
    id: 'channels',
    text: 'チャンネル設定',
    for_admin: true,
    icon: IconNames.COG
  },
  // 2023.09 未公開
  // {
  //   id: 'billings',
  //   text: '請求照会',
  //   for_admin: true,
  //   icon: IconNames.DOLLAR
  // },
];

// -- main component --
const PfUserHeader = memo(function PfUserHeader() {
  const dispatch = useDispatch();
  const login_id = getLoginId();
  const auth_state = useSelector(authSelector);

  const signOut = useCallback(() => {
    dispatch(authActions.signOut());
  }, [dispatch]);

  // -- render part --
  return (
    <PfHeader
      header_menu_items={header_menu_items}
      signOut={signOut}
      is_admin_user={auth_state.is_admin}
      login_id={login_id}
    />
  );
});
export default PfUserHeader;
